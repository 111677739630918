<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="dialogTitle"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-form-item prop='bank_card_name' label='持卡人'>
        <el-input
          v-model='form.data.bank_card_name'
          clearable
          placeholder='请输入持卡人'
        ></el-input>
      </el-form-item>
      <!-- 判断企业开户行如果多个就选择 -->
      <template v-if="limit_banks.length > 1">
        <el-form-item prop='bank_name' label='开户银行'>
          <el-select v-model="form.data.bank_name" style="width:100%;">
            <el-option v-for="item in limit_banks" :label="item.name" :value="item.name" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item prop='bank_name' label='开户行：'>
          <el-input
            v-model='form.data.bank_name'
            clearable
            placeholder='请输入开户行'
          ></el-input>
        </el-form-item>
      </template>
      <el-form-item prop='bank_card_no' label='银行卡号：'>
        <el-input
          v-model='form.data.bank_card_no'
          clearable
          placeholder='请输入银行卡号'
        ></el-input>
      </el-form-item>
      <el-form-item label=''>
        <p v-if="this.form.data.backpay_reason != ''" style="color:#f56c6c;"><i class="el-icon-warning-outline"></i>{{this.form.data.backpay_reason}}</p>
        <p v-if="this.form.data.limit_bank_name != ''" style="color:#f56c6c;"><i class="el-icon-warning-outline"></i>企业已限定{{this.form.data.limit_bank_name}}，请修改后再提交</p>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'ReplenishEdit',
    props: ['curStatus'],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            bank_card_no: '',
            bank_name: '',
            bank_card_name: '',
          },
          rules: {
            bank_card_no: [{ required: true, message:'请输入银行卡号', trigger: 'change'}],
            bank_name: [{ required: true, message: '请输入开户行', trigger: 'change'}],
            bank_card_name: [{ required: true, message:'持卡人姓名', trigger: 'change'}],
          }
        },
        status: 1,
        title:{
          1:'完善银行卡',
          2:'修改银行卡',
          3:'更新银行卡',
        },
        dialogTitle: '完善银行卡',
        limit_banks: [],
      
      }
    },
    watch:{
      curStatus(val) {
        this.status = val
        this.dialogTitle = this.title[val]
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
        this.limit_banks = row.limit_banks;
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let _param = {
              id: this.form.data.id,
              bank_card_no: this.form.data.bank_card_no,
              bank_name: this.form.data.bank_name,
              bank_card_name: this.form.data.bank_card_name,
            }
            this.$http.post('/admin/reissue/perfectCard', _param).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
                this.$message.success('操作成功！')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
