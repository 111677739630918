<template>
  <el-dialog
    append-to-body
    width="900px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tableWrap">
      <el-divider content-position="left">员工信息</el-divider>
      <el-descriptions class="margin-top" title="" :column="2" size="medium" border>
        <el-descriptions-item label="名称">{{ detailInfo.employee_name }}</el-descriptions-item>
        <el-descriptions-item label="性别">{{ detailInfo.sex === 1 ? '男' : '女' }}</el-descriptions-item>
        <el-descriptions-item label="电话">{{ detailInfo.phone }}</el-descriptions-item>
        <el-descriptions-item label="入职时间">{{ detailInfo.entry_at }}</el-descriptions-item>
        <el-descriptions-item label="用工单位">{{ detailInfo.company_name}}</el-descriptions-item>
        <el-descriptions-item label="开票单位">{{ detailInfo.invoice_company_name }}</el-descriptions-item>
        <el-descriptions-item label="基本工资">{{ detailInfo.base_salary }}元</el-descriptions-item>
        <el-descriptions-item label="状态">{{ detailInfo.employee_status === 1 ? '在职' : '离职' }}</el-descriptions-item>
        <el-descriptions-item label="离职时间" v-if="detailInfo.employee_status === 2">{{ detailInfo.employee_status === 2 ? detailInfo.dimission_at : '' }}</el-descriptions-item>
      </el-descriptions>

      <el-divider content-position="left">{{(detailInfo.status == 5 || detailInfo.status == 6) ? '工资信息' : '待补发信息'}}</el-divider>
      <template v-if="detailInfo.status == 5 || detailInfo.status == 6">
        <el-descriptions class="margin-top" title="" :column="2" size="medium" border>
          <el-descriptions-item label="不发月份">{{ detailInfo.month }}月</el-descriptions-item>
          <el-descriptions-item label="应发工资/元">{{ detailInfo.salary }}</el-descriptions-item>
          <el-descriptions-item label="实发工资/元">{{ detailInfo.real_salary }}</el-descriptions-item>
          <el-descriptions-item label="持卡人姓名">{{ detailInfo.bank_card_name }}</el-descriptions-item>
          <el-descriptions-item label="开户银行">{{ detailInfo.bank_name }}</el-descriptions-item>
          <el-descriptions-item label="银行卡号">{{ detailInfo.bank_card_no }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ detailInfo.backpay_time }}</el-descriptions-item>
          <el-descriptions-item label="不发原因">{{ detailInfo.backpay_reason }}</el-descriptions-item>
          <el-descriptions-item label="状态">{{ detailInfo.is_confirm_reissue == 0 ? '待确认' :'已确认'}}</el-descriptions-item>
          <el-descriptions-item label="申请时间">{{ detailInfo.apply_time }}</el-descriptions-item>
          <template v-if="detailInfo.is_confirm_reissue == 1">
            <el-descriptions-item label="确认时间">{{ detailInfo.confirm_reissue_time}}</el-descriptions-item>
          </template>
          <template v-if="detailInfo.status == 6">
            <el-descriptions-item label="不予发放时间">{{ detailInfo.no_pay_time}}</el-descriptions-item>
          </template>
        </el-descriptions>
      </template>
      <template v-else>
        <el-descriptions class="margin-top" title="" :column="2" size="medium" border>
          <el-descriptions-item label="待补发月份">{{ detailInfo.month }}月</el-descriptions-item>
          <el-descriptions-item label="工资/元">{{ detailInfo.real_salary }}</el-descriptions-item>
          <el-descriptions-item label="补发时间" v-if="detailInfo.status === 1">{{ detailInfo.repay_time }}</el-descriptions-item>
          <el-descriptions-item label="退回时间" v-if="detailInfo.status === 2">{{ detailInfo.backpay_time }}</el-descriptions-item>
          <el-descriptions-item label="申请补发时间" v-if="detailInfo.status === 3 || detailInfo.status === 4">{{ detailInfo.apply_time }}</el-descriptions-item>
          <el-descriptions-item label="持卡人">{{ detailInfo.bank_card_name }}</el-descriptions-item>
          <el-descriptions-item label="基本工资">{{ detailInfo.base_salary }}元</el-descriptions-item>
          <el-descriptions-item label="开户银行">{{ detailInfo.bank_name}}</el-descriptions-item>
          <el-descriptions-item label="银行卡号">{{ detailInfo.bank_card_no }}</el-descriptions-item>
          <template v-if="detailInfo.status === 1 || detailInfo.status === 2">
            <el-descriptions-item label="退回原因">{{ detailInfo.backpay_reason }}</el-descriptions-item>
          </template>
          <template v-if="detailInfo.status === 3 || detailInfo.status === 4">
            <el-descriptions-item label="状态">{{ statusObj[detailInfo.status]}}</el-descriptions-item>
            <el-descriptions-item label="补发时间">{{ detailInfo.repay_time }}</el-descriptions-item>
          <el-descriptions-item label="不发原因" v-if="detailInfo.status === 3">{{ detailInfo.backpay_reason }}</el-descriptions-item>
          </template>
        </el-descriptions>
      </template>

      


    </diV> 
  </el-dialog>
</template>
<script>
import {getStorage} from '@/storage'
import common from '@/util'
  export default {
    name: 'PerformanceDrawDetail',
    data() {
      return {
        visible: false,
        icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
        detailInfo: {},
        statusObj: {
          1: '无卡补发',
          2: '退回补发',
          3: '待补发',
          4: '已补发'
        },
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      // 获取员工详情
      getDetail(row) {
        if(!!row) {
          this.$http.get('/admin/reissue/detail', {params:{ id: row.id}}).then(res => {
            if(res.code === 1) {
             this.detailInfo = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
     
    }
  }
</script>
<style scoped lang="scss">
// 详情自定义table表格样式
.tableWrap .list {
  padding: 0 12px;
  margin-bottom: 30px;
}
 .tableWrap table {
  width: 100%;
  border-collapse: collapse;
}
 .tableWrap table td{
  height: 30px;
  line-height: 22px;
  padding: 5px;
  border: 1px solid #ddd;
}
.tableWrap .des {
  background: #fbfbfb;
   width: 90px;
}
.tableWrap .d-title {
  height: 80px;
  line-height: 80px;
}

</style>
