<template>
  <div class="main-view">
    <div class="tableType">
      <el-radio-group v-model="table.params.status" @change="handleTabs">
        <el-radio-button :label="1">无卡员工({{statusTotal.empty_total}})</el-radio-button>
        <el-radio-button :label="2">退回员工({{statusTotal.back_total}})</el-radio-button>
        <el-radio-button :label="5">暂不发放({{statusTotal.withhold_total}})</el-radio-button>
        <el-radio-button :label="3">待补发({{statusTotal.ing_total}})</el-radio-button>
        <el-radio-button :label="4">已补发({{statusTotal.paid_total}})</el-radio-button>
        <el-radio-button :label="6">不予发放({{statusTotal.no_paid_total}})</el-radio-button>
      </el-radio-group>
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_id">
          <el-select v-model="table.params.company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="补发月份" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="month"
            format="yyyy 年 MM 月"
            value-format="yyyy-MM"
            placeholder="选择月">
          </el-date-picker>
        </el-form-item>
        <template v-if="this.table.params.status === 3 || this.table.params.status === 4">
          <el-form-item label="补发原因" prop="">
            <el-select v-model="table.params.reissue_status" placeholder="请选择" clearable filterable>
              <el-option
                v-for="item in causeOpt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <!--  只有总公司账号才有权限选择开票公司 -->
        <el-form-item v-if="this.icId == 0" label="开票单位" prop="invoice_company_id">
          <el-select v-model="table.params.invoice_company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in invoiceCompanyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <template v-if="this.table.params.status ===3 && this.icId ==0">
          <el-button :size="subUnitSize" type="danger" plain icon="el-icon-download" @click="handleSendBatch">批量补发</el-button>
        </template>
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExport">全部导出</el-button>
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExportBatch">批量导出</el-button>
      </div>
    </div>
    <!-- 无卡员工 -->
    <template v-if="this.table.params.status ===1">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:settlement_id="{row}">
          <span>{{row.settlement.company_name}}</span>
        </template>
        <template v-slot:real_salary="{row}">
          <span>¥{{row.real_salary}}</span>
        </template>
        <template v-slot:settlement="{row}">
          <span>{{row.settlement.month}}月</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" @click='showEdit(row)'>完善银行卡</el-button>
          <el-button type="text" @click="showDetail(row)">查看</el-button>
        </template>
      </VTable>
    </template>
    <!-- 退回员工 -->
    <template v-if="this.table.params.status ===2">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field2"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:settlement_id="{row}">
          <span>{{row.settlement.company_name}}</span>
        </template>
        <template v-slot:real_salary="{row}">
          <span>¥{{row.real_salary}}</span>
        </template>
        <template v-slot:settlement="{row}">
          <span>{{row.settlement.month}}月</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" @click='showEdit(row)'>修改</el-button>
          <el-button type="text" @click="showDetail(row)">查看</el-button>
        </template>
      </VTable>
    </template>
    <!-- 待补发 -->
    <template v-if="this.table.params.status ===3 && this.icId == 0">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field3"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:settlement_id="{row}">
          <span>{{row.settlement.company_name}}</span>
        </template>
        <template v-slot:settlement="{row}">
          <span>{{row.settlement.month}}月</span>
        </template>
        <template v-slot:real_salary="{row}">
          <span>¥{{row.real_salary}}</span>
        </template>
        <template v-slot:reissue_status="{row}">
          <span>{{reissueStatus[row.reissue_status]}}</span>
        </template>
        <template v-slot:action="{row}">
          <!-- icId: 0  总公司账号显示补发操作-->
          <el-button type="text" @click="handleReissue(row)">补发</el-button>
          <el-button type="text" @click="showDetail(row)">查看</el-button>
        </template>
      </VTable>
    </template>
    <!-- 分公司账号登录 -->
    <template v-else-if="this.table.params.status ===3">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field3"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:settlement_id="{row}">
          <span>{{row.settlement.company_name}}</span>
        </template>
        <template v-slot:settlement="{row}">
          <span>{{row.settlement.month}}月</span>
        </template>
        <template v-slot:real_salary="{row}">
          <span>¥{{row.real_salary}}</span>
        </template>
        <template v-slot:reissue_status="{row}">
          <span>{{reissueStatus[row.reissue_status]}}</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" @click='showEdit(row)'>更新</el-button>
          <el-button type="text" @click="showDetail(row)">查看</el-button>
        </template>
      </VTable>
    </template>
    <!-- 已补发 -->
    <template v-if="this.table.params.status ===4">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field4"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:settlement_id="{row}">
          <span>{{row.settlement.company_name}}</span>
        </template>
        <template v-slot:settlement="{row}">
          <span>{{row.settlement.month}}月</span>
        </template>
        <template v-slot:real_salary="{row}">
          <span>¥{{row.real_salary}}</span>
        </template>
        <template v-slot:reissue_status="{row}">
          <span>{{reissueStatus[row.reissue_status]}}</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" @click="showDetail(row)">查看</el-button>
        </template>
      </VTable>
    </template>
    <!-- 暂不发放或不予发放 -->
    <template v-if="this.table.params.status === 5 || this.table.params.status === 6">
      <VTable
        has-pagionation
        title=''
        :field="field5"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showSelect="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:settlement_id="{row}">
          <span>{{row.settlement.company_name}}</span>
        </template>
        <template v-slot:settlement="{row}">
          <span>{{row.settlement.month}}月</span>
        </template>
        <template v-slot:salary="{row}">
          <span>¥{{row.salary}}</span>
        </template>
        <template v-slot:real_salary="{row}">
          <span>¥{{row.real_salary}}</span>
        </template>
        <template v-slot:is_confirm_reissue="{row}">
          <span>{{row.is_confirm_reissue === 0 ? '待确认' : '已确认'}}</span>
        </template>
        <template v-slot:action="{row}">
          <!-- 待确认状态的操作按钮 is_confirm_reissue: 0-待确认；1-已确认； 并且状态为暂不发放-->
          <template v-if="icId != 0 && row.is_confirm_reissue == 1 && table.params.status == 5">
            <!-- 分公司账号-已确认状态的操作 -->
            <el-button type="text" @click="showGrant(row, 1)">申请发放</el-button>
            <el-button type="text" @click="handleApply(row, 0)">申请不发</el-button>
          </template>
          <template v-if="icId == 0 && row.is_confirm_reissue == 0 && table.params.status == 5">
            <!-- 总管理员账号操作 -->
            <el-button type="text" @click="showAffirm(row)">确认</el-button>
            <el-button type="text" @click="showRejectl(row, 0)">驳回</el-button>
          </template>
          <template v-if="icId == 0 && table.params.status == 6 && row.is_confirm_reissue == 0">
            <el-button type="text" @click="handleArrest(row, 1)">确认</el-button>
            <el-button type="text" @click="handleArrest(row, 0)">驳回</el-button>
          </template>
          <el-button type="text" @click="showDetail(row)">查看</el-button>
        </template>
      </VTable>
    </template>
    

    <edit ref="edit" @refresh="getTable" :curStatus="curStatus"></edit>
    <detail ref="detail"></detail>
    <!-- <dimission ref="dimission" @refresh="getTable"></dimission> -->

    <!-- 补发 -->
    <el-dialog
      title="补发"
      :visible.sync="sendInfo.dialogVisible"
      width="30%"
      :before-close="handleClose">
      <p>补发人员：
        <!-- 批量显示人数，单次显示员工姓名 -->
        <span v-if="this.sendInfo.sendStaff.length >= 1">{{this.sendInfo.sendStaff.length}}人</span>
        <span v-else>{{this.sendInfo.employee_name}}</span>
      </p>
      <p>补发金额：￥{{sendInfo.sendSum}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sendInfo.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSend">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 申请发放 -->
    <el-dialog
      :title=" this.icId == 0 ? '确认暂不发放' : '确认提交申请'"
      :visible.sync="applyInfo.dialogVisible"
      width="30%"
      :before-close="handleClose">
      <p>发放人员：
        <span>{{this.applyInfo.employee_name}}</span>
      </p>
      <p>{{this.icId == 0 ? '工资金额' : '实发工资'}}：￥{{applyInfo.sendSum}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="applyInfo.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleGrant">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
// import Dimission from './components/Dimission.vue'
import VTable from '@/components/VTable';
import {exportData} from "@/util/index"
import {getStorage} from '@/storage'
import { mapGetters } from 'vuex';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins: [mixinTable],
  components: {
    Edit,
    Detail,
    VTable
  },
  computed: {
    ...mapGetters({
      invoiceCompanyOpt: 'invoiceCompany', // 获取开票单位列表
      companyOpt: 'companyList', // 获取企业列表
    })
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"80"},
        { name: "employee_name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "id_card_no", label: "身份证号",width: "180", hidden: false},
        { name: "settlement_id", label: "用工单位", width:"180", hidden: false},
        { name: "invoice_company_name", label: "开票单位", width:"180", hidden: false},
        { name: "bank_card_name", label: "持卡人", hidden: false},
        { name: "settlement", label: "待补发月份", hidden: false},
        { name: "real_salary", label: "工资(元)", hidden: false},
        { name: "created_at", label: "创建时间", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID", width:"80"},
        { name: "employee_name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "id_card_no", label: "身份证号",width: "180", hidden: false},
        { name: "settlement_id", label: "用工单位", width:"180", hidden: false},
        { name: "invoice_company_name", label: "开票单位", width:"180", hidden: false},
        { name: "bank_card_name", label: "持卡人", hidden: false},
        { name: "bank_name", label: "开户行", hidden: false},
        { name: "bank_card_no", label: "银行卡号",width:"180", hidden: false},
        { name: "settlement", label: "月份", hidden: false},
        { name: "real_salary", label: "工资/元", width:"120", hidden: false},
        { name: "backpay_time", label: "退回时间", hidden: false},
        { name: "backpay_reason", label: "退回原因", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      field3: [
        { name: "id", label: "ID", width:"80"},
        { name: "employee_name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "id_card_no", label: "身份证号",width: "180", hidden: false},
        { name: "settlement_id", label: "用工单位", width:"180", hidden: false},
        { name: "invoice_company_name", label: "开票单位", width:"180", hidden: false},
        { name: "bank_card_name", label: "持卡人", hidden: false},
        { name: "bank_name", label: "开户行", hidden: false},
        { name: "bank_card_no", label: "银行卡号", width:"180", hidden: false},
        { name: "settlement", label: "月份", hidden: false},
        { name: "real_salary", label: "工资/元", width:"120", hidden: false},
        { name: "apply_time", label: "申请补发时间",width:"160", hidden: false},
        { name: "reissue_status", label: "补发原因", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      field4: [
        { name: "id", label: "ID", width:"80"},
        { name: "employee_name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "id_card_no", label: "身份证号",width: "180", hidden: false},
        { name: "settlement_id", label: "用工单位", width:"180", hidden: false},
        { name: "invoice_company_name", label: "开票单位", width:"180", hidden: false},
        { name: "bank_card_name", label: "持卡人", hidden: false},
        { name: "bank_name", label: "开户行", hidden: false},
        { name: "bank_card_no", label: "银行卡号", hidden: false},
        { name: "settlement", label: "月份", hidden: false},
        { name: "real_salary", label: "工资/元",width:"120", hidden: false},
        { name: "created_at", label: "申请补发时间", width:"160", hidden: false},
        { name: "reissue_status", label: "补发原因", hidden: false},
        { name: "repay_time", label: "补发时间",width:"160", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      field5: [
        { name: "id", label: "ID", width:"80"},
        { name: "employee_name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", width:"120", hidden: false},
        { name: "settlement_id", label: "用工单位", width:"180", hidden: false},
        { name: "invoice_company_name", label: "开票单位", width:"180", hidden: false},
        { name: "settlement", label: "不发月份", hidden: false},
        { name: "salary", label: "应发工资/元", width:"120", hidden: false},
        { name: "real_salary", label: "实发工资/元", width:"120", hidden: false},
        { name: "backpay_reason", label: "不发原因", hidden: false},
        { name: "backpay_time", label: "创建时间",width:"160", hidden: false},
        { name: "is_confirm_reissue", label: "状态",width:"80", hidden: false},
        { name: "action", label: "操作",width: "200", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          ids: [],
          date: '',
          company_id: '',
          invoice_company_id: '',
          status: 1,
          name: '',
          year: '',
          month: '',
          reissue_status:'',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      reissueStatus: {
        1:'无卡补发',
        2:'退回补发',
        5:'暂扣补发'
      },
      causeOpt: [{
        id: 1,
        name: '无卡补发'
      },{
        id: 2,
        name: '退回补发'
      }],
      statusTotal: {
        empty_total: '',
        back_total: '',
        ing_total: '',
        paid_total: '',
        withhold_total: '',
        no_paid_total: '',
      },
      rowId: '',
      isDetail: false,
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
      curStatus: '',

      sendInfo: {
        dialogVisible: false,
        sendStaff: [],
        sendSum: '',
        employee_name:'',
      },
      applyInfo: {
        dialogVisible: false,
        employee_name: '',
        sendSum: '',
      }
     
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      if(this.table.params.date != '') {
        let date = this.table.params.date
        this.table.params.year = this.$moment(date).format('YYYY')
        this.table.params.month = Number(this.$moment(date).format('MM'))
      }
      this.curStatus = this.table.params.status;
      this.table.data = [];
      this.$http.get('/admin/reissue/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;

          this.statusTotal.empty_total = res.data.empty_total;
          this.statusTotal.back_total = res.data.back_total;
          this.statusTotal.ing_total = res.data.ing_total;
          this.statusTotal.paid_total = res.data.paid_total;
          this.statusTotal.withhold_total = res.data.withhold_total;
          this.statusTotal.no_paid_total = res.data.no_paid_total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 重置
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.year = '';
      this.table.params.month = '';
      this.table.params.page = 1;
      this.getTable();
    },
    // 全部导出
    handleExport() {
      if(this.table.data.length > 0) {
        let _params = {
          status: this.table.params.status,
          reissue_status: this.table.params.reissue_status,
          name: this.table.params.name,
          company_id: this.table.params.company_id,
          year: this.table.params.year,
          month: this.table.params.month,
        }
        exportData(_params,'/admin/reissue/export')
      } else {
        this.$message.warning('没有需要导出的数据！')
      }
    },
    // 批量导出
    handleExportBatch() {
      if(this.table.params.ids.length > 0) {
        this.table.params.ids = this.table.params.ids.toString()
        exportData(this.table.params,'/admin/reissue/export')
      } else  {
         this.$message.warning("请选择要批量导出的数据！");
      }
    },
    // 批量选择的数据
    handleSelectionChange(batchData) {
      console.log(batchData)
      this.table.params.ids = [];
      this.sendInfo.sendStaff = [];
      this.sendInfo.sendSum = 0
      if(batchData.length > 0) {
        batchData.forEach(v => {
          this.table.params.ids.push(v.id)
          this.sendInfo.sendStaff.push(v.employee_name)
        })
        this.sendInfo.sendSum = batchData.reduce(this.getSum, 0).toFixed(2);
        console.log(this.sendInfo.sendSum);
      }
    },
    getSum(total, num) {
      return total + Number(num.real_salary);
    },
    // 批量补发
    handleSendBatch(batchData) {
     if(this.table.params.ids.length > 0) {
        this.sendInfo.id = this.table.params.ids.toString()
        this.sendInfo.dialogVisible = true;
      } else  {
         this.$message.warning("请选择要批量补发的数据！");
      }
    },
    // 补发
    handleReissue(row) {
      this.sendInfo.sendStaff = []
      this.sendInfo.dialogVisible = true;
      this.sendInfo.id = row.id
      this.sendInfo.employee_name = row.employee_name
      this.sendInfo.sendSum = row.real_salary
    },
    // 补发
    handleSend() {
      this.$http.post('/admin/reissue/repay', { id: this.sendInfo.id }).then(res => {
        if(res.code ===1) {
          this.$message.success('操作成功！')
          this.table.params.status = 3;
          this.getTable();
          this.sendInfo.dialogVisible = false;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 关闭补发弹窗
    handleClose() {
      this.sendInfo.dialogVisible = false;
    },
    // 总账号-确认
    showAffirm(row) {
      this.applyInfo.dialogVisible = true;
      this.applyInfo.id = row.id
      this.applyInfo.employee_name = row.employee_name
      this.applyInfo.sendSum = row.real_salary
    },
    // 总账号-驳回
    showRejectl(row, type) {
      this.$confirm("确认驳回？", "确认", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.confirmFn(row.id, type);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    // 驳回
    confirmFn(rowId, type) {
      let _params = {
        id: rowId,
        is_confirm: type,  //0-驳回；1-确认暂不发放；
      }
      this.$http.post('/admin/reissue/confirm', _params).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          if(type == 1) {
            this.applyInfo.dialogVisible = false;
          }
          this.getTable();
        } else  {
          this.$message.error(res.msg)
        }
      })
    },
    // 分公司-申请发放
    showGrant(row) {
      this.applyInfo.dialogVisible = true;
      this.applyInfo.id = row.id
      this.applyInfo.employee_name = row.employee_name
      this.applyInfo.sendSum = row.real_salary
    },
    handleGrant() {
      if(this.icId == 0) {
        this.confirmFn(this.applyInfo.id, 1)
      } else {
        this.applyFn(this.applyInfo.id, 1)
      }
    },
    // 分公司-申请不发
    handleApply(row, type) {
      this.$confirm("是否申请不予发放？", "确认", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.applyFn(row.id, type);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    // type： 1：申请发放、0：申请不发
    applyFn(rowId, type) {
      let _params = {
        id: rowId,
        is_paid: type,  
      }
      this.$http.post('/admin/reissue/apply', _params).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          if(type == 1) {
            this.applyInfo.dialogVisible = false;
          }
          this.getTable();
        } else  {
          this.$message.error(res.msg)
        }
      })
    },
    // 总管理-不予发放
    handleArrest(row, type) {
      let str = type == 1 ? '不予发放' : '驳回';
      this.$confirm(`确认${str}？`, "确认", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.arrestFn(row.id, type);
            done()
          } else {
            done()
          }
        }
      })
    },
    arrestFn(rowId, type) {
       let _params = {
        id: rowId,
        is_no_pay: type,  
      }
      this.$http.post('/admin/reissue/confirmNoPay', _params).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else  {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>